<template lang="pug">
  v-data-table.clickable(
    :headers="headers"
    :loading="areUsersPending"
    no-data-text="No Users Found"
    :options.sync="options"
    :server-items-length="haveUsersLoaded ? paginationData.default.mostRecent.total : 0"
    :items="users"
    @click:row="(item) => $router.push({name: 'edit-user', params: { userId: item.id }})"
    :footer-props="{ itemsPerPageOptions: [5,10,15] }"
  )
    template(v-slot:item.shop_id="{ item: user }") {{ user.shop.name }}
    template(
      #body.prepend
      v-if="currentUser.isAdmin || currentUser.isRegionAdmin"
    )
      tr
        td(colspan="100")
          v-btn(
            color="primary"
            block
            :to="{ name: 'edit-user', params: { userId: 'new' }, query: { shopId, regionId, msoId } }"
          )
            v-icon(
              left
            ) mdi-plus
            span(v-if="shop") Add User at {{ shop.name }}
            span(v-if="region") Add User at {{ region.name }}
            span(v-if="mso") Add User at {{ mso.name }}

</template>

<script>

import { useFind, useGet } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'UsersTable',
  props: {
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'First',
            value: 'firstName'
          },
          {
            text: 'Last',
            value: 'lastName'
          }
        ]
      }
    },
    shopId: {
      type: String,
      required: false
    },
    regionId: {
      type: String,
      required: false
    },
    msoId: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const { User } = context.root.$FeathersVuex.api

    const { Shop } = context.root.$FeathersVuex.api

    const { item: shop } = useGet({
      model: Shop,
      id: props.shopId
    })

    const { Region } = context.root.$FeathersVuex.api

    const { item: region } = useGet({
      model: Region,
      id: props.regionId
    })

    const { Msos } = context.root.$FeathersVuex.api

    const { item: mso } = useGet({
      model: Msos,
      id: props.msoId
    })

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...queryObj.value }

      if (props.search !== null && props.search !== '') {
        params.$or = [
          {
            firstName: {
              $regex: props.search,
              $options: 'gi'
            }
          },
          {
            lastName: {
              $regex: props.search,
              $options: 'gi'
            }
          }
        ]
      }

      return { query: { ...params } }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      const params = { ...queryObj.value }

      if (props.search !== null && props.search !== '') {
        params.$or = [
          {
            firstName: {
              $iRegexp: props.search
            }
          },
          {
            lastName: {
              $iRegexp: props.search
            }
          }
        ]
      }

      return { query: { ...params } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: users, isPending: areUsersPending, paginationData, haveLoaded: haveUsersLoaded } = useFind({
      model: User,
      params,
      fetchParams,
      paginate: true
    })

    return {
      shop,
      region,
      mso,
      options,
      sortBy,
      limit,
      users,
      queryObj,
      params,
      fetchParams,
      areUsersPending,
      haveUsersLoaded,
      paginationData
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/user']
    }
  }
}
</script>
